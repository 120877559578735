const idPortalApi = "90210df2b47784e2d33609d409731118c273ed47";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "http://www.manoelemidio.pi.leg.br/", //usada para link de compartilhamento
  dnsPortal: "www.manoelemidio.pi.leg.br",
  NomePortal: "Manoel Emídio",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 6, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/bnXbGxzV2a14N7Xt9`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15803.772614412424!2d-43.8728745!3d-8.00479965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x77f36a2bee23c59%3A0xe93eee1469ecdedd!2sManoel%20Em%C3%ADdio%2C%20PI%2C%2064875-000!5e0!3m2!1spt-BR!2sbr!4v1721130148786!5m2!1spt-BR!2sbr",
};
